import React from 'react'
import "./DetailArtikel.scss";
import Hutdesa from '../../assets/images/hutdesa.jpg';
import Kebun from '../../assets/images/kebun.jpg';
import { Link, useParams } from 'react-router-dom';
import FeatherIcon from "feather-icons-react";
import computer from '../../assets/images/computer.png';

const DetailArtikel = () => {
  const {id} = useParams()
  return (
    <section id="DetailArtikel">
      <div className="navbar">
        <Link to={"/"} className="link-back">
          <FeatherIcon icon="arrow-left"/>
          Kembali
        </Link>
      </div>
      <div className="container">
        
        <div className="news-content">
          {id === "hut-375-warga-diberi-limpahan-rezeki-desa-pangkul-makin-makmur" ? 
          <>
          <h2>HUT 375, Warga Diberi Limpahan Rezeki, Desa Pangkul Makin Makmur</h2>
            <p className='news-date'>Kamis, 29/12/2023, 21.00 WIB</p>
            <img src={Hutdesa} alt="News Image" />
            <p>Tahun ini, Desa Pangkul merayakan HUT 375 ditandai mengelar syukuran membaca surat Yasin dan doa di Balai Desa, Kamis, 28 Desember 2023.

            Kegiatan itu dihadiri Pj Wako Prabumulih, H Elman ST MM diwakili Staff Ahli, Mulyadi Karoman SPd MSi bersama sejumlah Forkompinda.

            Kades Pangkul, Zakaria Yadi SH MSi berharap, desanya di usia ke 375 tahun ini terus maju dan makin makmur.

            “Semoga, warga Desa Pangkul senantiasa diberikan limpahan rezeki. Sebagai tanda syukur di HUT 375, mengelar sedekah dusun membaca surat Yasin dan berdoa kepada sang khalik,” terang Jaka, sapaan akrabnya

            Harapannya, ke depan ada warga Desa Pangkul, menjadi Camat. Dan, tak mengajak warga terus membangun Desa Pangkul.

            “Desa Pangkul lebih baik dari desa lainnya. Tetap semangat dan optimis, lebih baik dan lebih maju. Bersyukur Pangkul dari tahun ke tahun diberikan limpahan rezeki,” kata dia.

            Ia juga meminta Pemkot Prabumulih terus membangun Desa Pangkul, melengkapi sarana prasarana dan infrastruktur. “Termasuk, pengecoran jalan di Desa Pangkul masih belum terlaksana,” sebutnya.

            Kades Pangkul, juga mengingatkan, di tahun politik beda pilihan hal biasa. Tetap, jaga kekompakan dan kesolidan. “Jangan terpecah belah, karena pemilu. Biar pemilu berjalan seiring waktu, jaga soliditas dan kekompakan antar masyarakat,” tandasnya.

            Staff Ahli Wako Prabumulih, Mulyadi Karoman SPd MSi mengataka, ke depan Desa Pangkul lebih baik lagi dan terus membangun.

            “Selamat, mari terus kita bangun. Usulan tadi, kita perjuangkan. Turut berbahagia dan bersukacita atas hari jari Desa Pangkul ke 375 ini,” pungkasnya. (rin)</p>

            <span>Sumber Berita: <a style={{color:"blue"}} href='https://fajarsumsel.com/hut-375-warga-diberi-limpahan-rezeki-desa-pangkul-makin-makmur/' target="_blank">https://fajarsumsel.com/hut-375-warga-diberi-limpahan-rezeki-desa-pangkul-makin-makmur/</a></span>
          </> 
          : 
          <>
          <h2>Kapolres Prabumulih Resmikan Kampung Tangkal Covid 19 kemarin (06/2) siang.</h2>
            <p className='news-date'>Prabumulih, 02/06/2020, 09:23 WIB</p>
            <img src={Kebun} alt="News Image" />
            <p> Dalam rangka meningkatkan ketahanan pangan dimasa pandemi covid 19
                Kapolres Prabumulih AKBP I Wayan Sudarmaya Lakukan Peresmian Kampung Tangkal Covid 19 Di Desa Pangkul Jawa.
                Dalam acara peresmian tersebut Kapolres
                Prabumulih AKBP I Wayan Sudarmaya mengatakan “Kita ketahui bersama saat ini seluruh dunia tengah mengalami pandemi covid-19 dan negara kita juga tidak luput dari serangan virus Covid 19, adanya wabah Covid 19 ini juga mempengaruhi seluruh lini kehidupan masyarakat, sehingga berdampak pada perkembangan ekonomi, sosial budaya, politik dan pertahanan keamanan.Kapolres Prabumulih Bersama Walikota dan unsur Forkopimda mengajak semua unsur untuk mewujudkan daya tangkal dari satuan kecil tingkat desa, yang kita beri nama Kampung Tangkal,Pendirian posko keamanan desa dengan binaan bhabinkamtibmas. Desa Pangkul ini, masyarakatnya memiliki kesadaran yang baik untuk menerapkan perotokol kesehatan dilingkungannya, selain itu desa Pangkul juga memiliki potensi lahan yang subur dan inilah yang menjadikan Desa Pangkul tepat untuk dijadikan sebagai Kampung Tangkal Covid-19.

                Sebelum melakukan peresmian Kampung Tangkal Covid 19 Kapolres, Walikota, Ketua DPRD, unsur Forkopinda.Danyon Zipur 2,Sub Denpom,Kejari,Kades Pangkul Melakukan panen Kangkung, dan Menabur bibit Ikan Lele,menanam ubi Kayu dan Bayam.dilahan masyarakat desa Pangkul Jawa.
              <br/>
              <span>Sumber Berita: <a style={{color:"blue"}} href='https://www.kotaprabumulih.go.id/kapolres-prabumulih-resmikan-kampung-tangkal-covid-19/' target="_blank">https://www.kotaprabumulih.go.id/kapolres-prabumulih-resmikan-kampung-tangkal-covid-19/</a></span>
              </p>
          </> 
          }
         </div>
        {/* <div className="news-list">
          <p className="orange-elipse">Other News</p>
          <ul>
            <li>
              <a href="news1.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Hutdesa} alt="News Image" />
                  </div>
                  
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="news2.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Hutdesa} alt="News Image" />
                  </div>
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="news3.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Hutdesa} alt="News Image" />
                  </div>
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="news4.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Hutdesa} alt="News Image" />
                  </div>
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="news5.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Hutdesa} alt="News Image" />
                  </div>
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
          </ul>
          
          <br />

          <p className="orange-elipse">Other News</p>
          <ul>
            <li>
              <a href="news1.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Hutdesa} alt="News Image" />
                  </div>
                  
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="news2.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Hutdesa} alt="News Image" />
                  </div>
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="news3.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Hutdesa} alt="News Image" />
                  </div>
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="news4.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Rumah} alt="News Image" />
                  </div>
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="news5.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Rumah} alt="News Image" />
                  </div>
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
          </ul>


        </div> */}
      </div>
        
    </section>
  )
}

export default DetailArtikel